<script lang="ts">
	import { buildHeroProps, heroRules, buildHeroColors } from './hero';
	import { headerHeight } from '$stores';
	import { page } from '$app/stores';
	import { CldImage, type CldImageProps } from 'svelte-cloudinary';
	import { classes } from '$stores';

	type HeroProps = ReturnType<typeof buildHeroProps>;
	type DeviceCldImageProps = { desktop: CldImageProps; mobile: CldImageProps };
	type HeroColors = ReturnType<typeof buildHeroColors>;

	const { routeInfo, pageInfo } = $page.data;
	const detailView = routeInfo.detailViewCollection?.items || [];
	const hero = detailView.find((item: Record<string, any>) => item.__typename === 'DetailViewHero');

	const hasHero = hero?.imageNew || hero?.image ? true : false;

	const heroClasses = classes.components.hero.default;
	const templateVars = {
		'{name}': pageInfo?.name || '',
		'{lat}': pageInfo?.coordinates?.lat,
		'{lon}': pageInfo?.coordinates?.lon
	};
	let heading = (hero?.heading || '').replaceFromMap(templateVars);
	let text = (hero?.text || '').replaceFromMap(templateVars);
	let heroDims = {
		desktop: buildHeroProps(heroRules.desktop),
		mobile: buildHeroProps(heroRules.mobile)
	};
	let deviceCldImageProps: DeviceCldImageProps;
	let heroColors: HeroColors;

	if (hasHero) {
		heroColors = buildHeroColors(hero);
		let desktopSrc: string = '';
		let mobileSrc: string = '';
		if (hero.imageNew) {
			if (hero.imageNew.externalDesktopSrc) {
				desktopSrc = hero.imageNew.externalDesktopSrc.replaceFromMap(templateVars);
				mobileSrc = hero.imageNew?.externalMobileSrc?.replaceFromMap(templateVars) || desktopSrc;
			} else {
				desktopSrc = hero.imageNew.desktop?.[0]?.public_id || '';
				mobileSrc = hero.imageNew.mobile?.[0]?.public_id || desktopSrc;
			}
		} else if (hero.image) {
			// this is the deprecated field
			desktopSrc = hero.image?.[0]?.public_id || '';
			mobileSrc = hero.mobileImage?.[0].public_id || desktopSrc;
		}

		const baseCldImageProps: CldImageProps = {
			deliveryType: hero.imageNew?.externalDesktopSrc ? 'fetch' : 'upload',
			priority: true,
			layout: 'constrained',
			crop: 'fill',
			gravity: 'faces',
			class: 'w-full h-full object-contain lg:object-cover object-top hero-gradient',
			alt: (hero.imageNew?.alt || hero.image?.[0]?.context?.custom?.alt || '').replaceFromMap(templateVars),
			src: '',
			height: '',
			width: ''
		};

		deviceCldImageProps = {
			desktop: {
				...baseCldImageProps,
				src: desktopSrc,
				width: heroDims.desktop.width.max,
				height: heroDims.desktop.height.max,
				aspectRatio: heroDims.desktop.height.aspect
			},
			mobile: {
				...baseCldImageProps,
				src: mobileSrc,
				width: heroDims.mobile.width.max,
				height: heroDims.mobile.height.max,
				aspectRatio: heroDims.mobile.height.aspect
			}
		};
	}
</script>

{#if hasHero}
	<div class="contents" style="--bgColor: {heroColors.desktop.background}; --mobileBgColor: {heroColors.mobile.background}; --headerHeight: {$headerHeight}px; --desktopImageHeight: {heroDims.desktop.height.max}px; --mobileImageHeight: {heroDims.mobile.height.max}px;">
		<!-- mobile -->
		<div class="lg:hidden bg-[--mobileBgColor] min-h-[572px] w-full flex flex-col justify-between overflow-hidden">
			<div class="relative z-10 flex flex-col justify-center w-full">
				<div class="flex px-8 pt-12 pb-4 flex-col gap-10 w-full">
					<div class="flex flex-col place-content-center w-full {heroColors.mobile.text}">
						{#if hero.heading}<h1 class={heroClasses.heading}>{heading}</h1>{/if}
						{#if hero.text}<p class={heroClasses.text}>{text}</p>{/if}
					</div>
					{#if $$slots.buttons}
						<div class="inline-flex flex-wrap gap-4">
							<slot name="buttons" />
						</div>
					{/if}
				</div>
			</div>
			<CldImage {...deviceCldImageProps.mobile} />
		</div>
		<!-- desktop -->
		<div class="max-lg:hidden bg-[--bgColor] h-[--desktopImageHeight] w-full flex overflow-hidden">
			<div class="relative z-10 flex flex-col justify-center w-full">
				<div class="flex flex-col gap-10 px-8">
					<div class="flex flex-col place-content-center {heroColors.desktop.text}">
						{#if hero.heading}<h1 class={heroClasses.heading}>{heading}</h1>{/if}
						{#if hero.text}<p class={heroClasses.text}>{text}</p>{/if}
					</div>
					{#if $$slots.buttons}
						<div class="inline-flex flex-wrap gap-4 justify-start">
							<slot name="buttons" />
						</div>
					{/if}
				</div>
			</div>
			<CldImage {...deviceCldImageProps.desktop} />
		</div>
	</div>
{/if}

<style>
	.hero-gradient {
		/* max-h-[calc(100dvh_-_var(--headerHeight))] */
		mask-image: linear-gradient(transparent, black 10%);
	}
	@media (min-width: 1024px) {
		.hero-gradient {
			mask-image: linear-gradient(to right, transparent, black 10%);
		}
	}
	@media (min-width: 1280px) {
	}
</style>
