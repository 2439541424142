// import { setHexColor } from '$lib/functions/utils'
import { setHexColor } from '$lib/functions/utils'
// import { twMerge } from 'tailwind-merge'

type HeroRules = Record<
		'desktop' | 'mobile',
		{
			width: {
				min: number
				max: number
				imagePortion: number
				increment: number
			}
			height: {
				min: number
				max: number
				aspect: number
			}
		}
	>
type HeroWidth = { min: number; max: number; range: number[] }
type HeroHeight = { min: number; max: number; aspect: number }

export const heroRules: HeroRules = {
	desktop: {
		width: {
			min: 1024,
			max: 1536,
			imagePortion: 0.5,
			increment: 200
		},
		height: {
			min: 631,
			max: 631,
			aspect: 1
		}
	},
	mobile: {
		width: {
			min: 330,
			max: 1023,
			imagePortion: 1,
			increment: 200
		},
		height: {
			min: 572,
			max: 572,
			aspect: 3 / 2
		}
	}
}

export function buildHeroProps(heroRule: HeroRules['desktop' | 'mobile']) {
	const heroWidth: HeroWidth = {
		min: heroRule.width.min * heroRule.width.imagePortion,
		max: heroRule.width.max * heroRule.width.imagePortion,
		range: []
	}
	const heroHeight: HeroHeight = {
		min: heroRule.height.min,
		max: heroRule.height.max,
		aspect: heroRule.height.aspect
	}

	let width = heroWidth.min

	while (width < heroWidth.max) {
		if (heroWidth.max - heroRule.width.increment > width) heroWidth.range.push(width)
		width = width + heroRule.width.increment
	}
	heroWidth.range.push(heroWidth.max)

	return {
		width: heroWidth,
		height: heroHeight
	}
}

export function buildHeroColors(hero:Record<string,any>) {

	const mobileTextColorOptions: Record<string,string> = {
		dark: 'text-black',
		light: 'text-white'
	}
	const desktopTextColorOptions: Record<string,string> = {
		// these are necessary so tailwind can pickup the large color size. We can't make these classes programmatically.
		dark: 'lg:text-black',
		light: 'lg:text-white'
	}

	// NOTE: In Contentful, we default to the desktop color if a mobile color isn't chosen. But in tailwind, it is mobile-first.
	const desktopColorProp = (desktopTextColorOptions[hero.textColor]) ? hero.textColor : 'light'
	const desktopTextColor = desktopTextColorOptions[desktopColorProp]
	const mobileTextColor = mobileTextColorOptions[hero.mobileTextColor] || mobileTextColorOptions[desktopColorProp]

	// const textColorArray = [mobileTextColor]
	// if (mobileTextColor !== desktopTextColor) textColorArray.push(desktopTextColorOptions[hero.textColor])
	// const textColor = twMerge(textColorArray)

	const desktopBackgroundColor = setHexColor(hero.baseColor,'#000000')
	const mobileBackgroundColor = setHexColor(hero.mobileBaseColor, desktopBackgroundColor)

	return {
		desktop: {
			text: desktopTextColor,
			background: desktopBackgroundColor
		},
		mobile: {
			text: mobileTextColor,
			background: mobileBackgroundColor
		}
	}
}