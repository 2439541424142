export function setHexColor(str: string, defaultColor = '#000000') {
	const regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/)
	let result = str || defaultColor

	if (!regex.test(result)) {
		result = `#${result}`
		if (!regex.test(result)) result = defaultColor
	}
	return result
}
